.success{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    color: #fff;
    padding-top: 50px;
    > .text-side{
        max-width: 600px;
        > h2{
            font-family: Arial;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 2.2px;
            margin-bottom: 20px;
        }
        >h1{
            font-family: Arial;
            font-size: 90px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 5.5px;
            margin-bottom: 20px;

        }
        >h3{
            font-family: Arial;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 3.3px;
            margin-bottom: 20px;

        }
        >p{
            font-family: Arian AMU;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.375px;
            margin-bottom: 20px;

        }
        > .back-to-home{
            max-width: 364px;
            height: 70px;
            border-radius: 100px;
            box-shadow: 0px 0px 10px 0px rgba(37, 111, 237, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.935px;
            cursor: pointer;
        }
    }
    > .image-side{
        max-width: 800px;
        max-height: 800px;
        >img{
            width: 100%;
            max-height: 100%;
        }
    }
}