.training-registration {
    background-color: rgba(54, 54, 54, 0.85);
    width: 700px;
    height: auto;
    // position: absolute;
    max-width: 100%;
    max-height: 90vh; /* Adjust max height to fit the viewport */
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    color: #fff; 
    padding: 20px 60px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    backdrop-filter: blur(6px); 
    position: fixed;
    z-index: 100;
    padding: 20px;

    .block{
        padding: 0 0;
    }
    >h1{
        color: #fff;
        font-size: 34px;
        text-align: center;
    }
    p{
        font-weight: 300;
        margin: 20px 0;
        
    }
    > .table{
        display: flex;

        
        >div{
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
            width: 100%;
            margin-bottom: 10px;
            transition: 0.5s ease;
            cursor: pointer;
            border-radius: transparent;
            > p{
                font-size: 14px;
                margin-left: 4px;
            }
        }
        >div:hover{
            background-color: #A35BFF;
        }
    }
    >div>p{
        text-align: left;
    }
    .price{
        display: flex;
        justify-content: space-between;
        >div{
            display: flex;
            align-items: center;
            gap: 14px;

        }
        p{
            margin: 0;
            font-size: 48px;
            font-weight: 700;
        }
        > del{
            font-size: 30px;
            font-weight: 700;
            color: #ccc;
        }
    }
    .left{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .rigth{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .button{
        width: 260px;
        margin: auto;
        background: linear-gradient(45deg, #A663DB, #6751B7);
        padding: 10px 40px;
        margin-top: 20px;
        border-radius: 8px;
        cursor: pointer;
        >p{
            text-align: center;
            margin: 0;
            font-size: 14px;
        }
    }
    .close-window{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .close-reg{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
        >p{
            position: absolute;
            top: 44%;
            left: 54%;
            transform: translate(-50%, -50%);
            margin: 0;
            line-height: 0;
        }
    }
}


@media (max-width: 800px) {
    .training-registration{
        overflow-y: scroll;
        // width: 500px;
    }
}
@media (max-width: 768px) {
    .table {
      flex-direction: column;
      gap: 15px;
    }
  
    .price {
      flex-direction: column;
      text-align: center;
    }
  
    .block {
      padding: 10px;
      min-width: unset;
      font-size: 0.9rem;
    }
  
    .button {
      padding: 12px 20px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .training-registration {
      padding: 10px;
    }
  
    .price {
      font-size: 1rem;
    }
  
    .block {
      font-size: 0.8rem;
    }
  
    .button {
      font-size: 0.9rem;
    }
  }