.home-services{
    padding: 90px 20px 0px 20px;

    &-title{
        text-align: center;
        // font-family: Arian AMU;
        font-size: 48px;
        font-weight: 700;
        color: var(--blueviolet);
    }
    &-items{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 90px;
        &-item:last-child{
            background: none;
            background-color: #272727;
        }
        &-item{

            width: 405px;
            height: 600px;
            border: 3px solid var(--blueviolet);
            border-radius: 20px;
            box-shadow: 1px 1px 15px black;
            &-img{
                height: 300px;
                background: #A35BFF;
                border-radius: 17px 17px 0 0;

                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 250px;
                }
            }
            &-desc{
                height: 200px;
                display: flex;
                justify-content: space-between;
                text-align: center;
                flex-direction: column;
                // align-items: center;
                h3{
                    margin-top: 30px;
                    // font-family: Arian AMU;
                    font-size: 31px;
                    font-weight: 700;
                    color: var(--white);
                    line-height: 50px;
                }
                button{
                    width: 300px;
                    margin:  0 auto;
                    padding: 10px 0;
                    // font-family: Arian AMU;
                    font-size: 21px;
                    font-weight: 700;
                    letter-spacing: 0.055em;
                    background-color: transparent;
                    border: 2px solid var(--blueviolet);
                    border-radius: 15px;
                    // margin-top: 50px;
                    transform: translateY(50px);
                    color: var(--white);
                    box-shadow: 3px 3px 3px rgb(24, 24, 24);
                    cursor: pointer;
                    transition: all 200ms ease;
                    &:hover{
                        background: #894CD9;
                    }
                }
            }
            &-top{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-top: 35px;
                div{
                    display: flex;
                    justify-content: center;
                    background-color: #FF525D;
                    width: 180px;
                    border-radius: 0 15px 15px 0;
                    padding: 14px 0;
                    img{
                        width: 40px;
                    }
                }
                h3{
                    // font-family: Arian AMU;
                    width: 200px;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.055em;
                    color: var(--white);
                    margin-left: 15px
                }
            }
            &-bottom{
                padding: 50px 22px 45px 22px;
                text-align: center;
                &-item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: var(--white);
                    flex-wrap: wrap;
                    background-color: var(--grey);
                    margin-top: 20px;
                    // padding: 0 20px;
                    border-radius: 5px;
                    position: relative;
                    cursor: pointer;
                    h4{
                        // font-family: Arian AMU;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 45px;
                        padding-left: 13px;
                    }
                    img{
                        opacity: 0;
                        position: absolute;
                        right: 0;
                        transition: all 300ms ease;
                    }
                    &:hover{
                        // justify-content: flex-end;
                        // gap: 54px;
                        padding: 0;
                        button{
                            opacity: 0;
                        }
                        img{
                            opacity: 1;
                        }
                        
                    }
                }
                
            }
        }  
    }

    .btn{
        width: 300px;
        margin:  0 auto;
        padding: 15px 0;
        // font-family: Arian AMU;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0.055em;
        background-color: transparent;
        background: var(--blueviolet);
        border: 2px solid var(--blueviolet);
        border-radius: 15px;
        // margin-top: 50px;
        transform: translateY(50px);
        color: var(--white);
        box-shadow: 3px 3px 3px rgb(24, 24, 24);
        cursor: pointer;
        transition: all 200ms ease;
        &:hover{
            background: #894CD9;
        }
    }
    

    @keyframes appearItem {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
        0%{
            opacity: 0;
        }
    }
}


@media screen and (max-width: 1400px){
    .home-services-items{
        justify-content: center;
        gap: 80px;
    }
}
@media screen and (max-width: 1100px){
    .home-services-items{
        justify-content: center;
        gap: 80px;
    }
}
@media screen and (max-width: 600px){
    .home-services{

    }
    .home-services-title{
        font-size: 36px;
    }
}
@media screen and (max-width: 450px){
    .home-services-title{
        font-size: 30px;
    }
    .home-services-items-item{
            width: 330px;
    }
    .home-services-items-item:last-child{
        background: none;
        background-color: #272727;
    }
    .home-services-items-item-top{
        padding-top: 25px;
        h3{
            font-size: 15px
        }
    }
    .home-services-items-item-bottom{
        padding: 30px 18px 25px 18px;

    }
    .home-services-items-item-bottom-item{
        h4{
            font-size: 11px;
        }
        button{
            font-size: 9px;
        }
    }
    .home-services-items-item-bottom-button{
        padding: 9px 42px;
        font-size: 17px;
        margin-top: 25px;
    }
    .home-services-items-item-desc{
        h3{            
            font-size: 25px;
        }
        button{
            width:  250px;
            font-size: 17px;
            margin-top: 0;
        }

    }
    
}

