.swiper{
    height: auto;
}
.swiper-button-next::after,.swiper-button-prev::after{
    display: none;
}
.swiper-pagination{
    display: none;
}

.treaning-slider{
    padding: 0 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20vh;
    }
    &-title{
        width: 630px;
        .p{
            color: #f9f9f9;
            font-size: 15px;
            font-weight: 400;
            

        }
        h1{
            height: auto;
            span{
                // font-family: "Arian AMU";
                font-size: 48px;
                font-weight: 700;
                line-height: 80.29px;
                text-align: left;
                color: var(--white);

            }
        

        }
        &-button{
            display: flex;
            gap: 40px;
            margin: 30px 0;
            
            button{
                // font-family: Arian AMU;
                font-size: 18px;
                font-weight: 700;
                line-height: 26.96px;
                letter-spacing: 0.055em;
                text-align: left;
                background: var(--blueviolet);
                width: 245px;
                height: 63px;
                border-radius: 175px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white);
                border: none;
                cursor: pointer;
                transition: 0.5s ease;
                &:last-child{
                    border: 2px solid var(--white);
                    background: transparent;
                }
            }
            button:hover{
                background-color: #A35BFF;
                color: #fff;
                border: 2px solid #A35BFF;
            }
        }
    }
    &-img{
        img{
            max-width: 500px;
            max-height: 500px;
        }
    }
}





@media screen and (max-width: 1435px) {
    .treaning-slider{
        &-title{
            h1{
                span{
                    font-size: 34px;
                    line-height: 67px;

                }
            }
            &-button{
                button{
                    font-size: 14px;
                    width: 210px;
                    height: 50px;
                }
            }
        }
        &-img{
            img{

                width: 580px;
            }
        }
    }
}


@media screen and (max-width: 1282px) {
    .treaning-slider{
        &-title{
            width: 470px;
            h1{
                span{
                    font-size: 30px;
                    line-height: 57px;

                }
            }
        }
        &-img{
            img{

                width: 530px;
            }
        }
    }
}


@media screen and (max-width: 1182px) {
    .treaning-slider{
        &-title{
            width: 430px;
            h1{
                height: 145px;
                span{
                    font-size: 27px;
                    line-height: 50px;

                }
            }
        }
        &-img{
            img{

                width: 480px;
            }
        }
    }
}


@media screen and (max-width: 1102px) {
    .treaning-slider{
        &-title{
            width: 420px;
            h1{
                height: 130px;
                span{
                    font-size: 28px;
                    line-height: 43px;

                }
            }
            &-button{
                button{
                    font-size: 15px;
                    width: 220px;
                    height: 50px;  
                }
            }
        }
        &-img{
            img{

                width: 450px;
            }
        }
    }
}


@media screen and (max-width: 1010px) {
    .treaning-slider{
        .container{
            display: block;
        }
        &-title{
        width: 630px;

            h1{
                height: 200px;
                span{
                    font-size: 42px;
                    line-height: 80.29px;

                }
            }
            &-button{
                button{
                    width: 245px;
                    height: 63px; 
                    font-size: 18px;
                }
            }
        }
        &-img{
            img{

                width: 100%;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .treaning-slider{
        .container{
            display: block;
        }
        &-title{
        width: 550px;

            h1{
                height: 180px;
                span{
                    font-size: 36px;
                    line-height: 65px;

                }
            }
            &-button{
                button{
                    width: 220px;
                    height: 55px; 
                    font-size: 16px;
                }
            }
        }
        &-img{
            img{

                width: 100%;
            }
        }
    }
}


@media screen and (max-width: 683px) {
    .treaning-slider{
        .container{
            display: block;
        }
        &-title{
        width: 500px;

            h1{
                height: 150px;
                span{
                    font-size: 30px;
                    line-height: 50px;

                }
            }
            &-button{
                button{
                    width: 180px;
                    height: 47px; 
                    font-size: 13px;
                }
            }
        }
        &-img{
            img{

                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .treaning-slider{
        .container{
            display: block;
        }
        &-title{
        width: 100%;

            h1{
                height: 110px;
                span{
                    font-size: 30px;
                    line-height: 40px;

                }
            }
            &-button{
                button{
                    width: 160px;
                    height: 37px; 
                    font-size: 11px;
                }
            }
        }
        &-img{
            img{

                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 526px) {
    .treaning-slider{
        padding: 0 40px;
        .container{
            display: block;
        }
        &-title{
        // width: 370px;

            h1{
                height: 90px;
                span{
                    font-size: 21px;
                    line-height: 30px;

                }
            }
            &-button{
                button{
                    width: 140px;
                    height: 34px; 
                    font-size: 9px;
                    margin: 30px 0 0 0;
                }
            }
        }
        &-img{
            img{

                width: 100%;
            }
        }
    }
}