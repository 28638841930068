.home-friends{
    margin: 90px 80px 0 80px;
    &-title{
        text-align: center;
        // font-family: Arian AMU;
        font-size: 48px;
        font-weight: 700;
        color: var(--blueviolet);
        margin-bottom: 100px;
    }
    &-items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 90px;
        img{

            // width: 105px;
            cursor: pointer;
        }
    }
    .swiper{
        text-align: center;
    }
    .swiper-wrapper{
        align-items: center;
    }
}



@media screen and (max-width: 1100px){
    .home-friends-items{
        justify-content: center;
        align-items: center;
        gap: 80px;
    }
}
@media screen and (max-width: 900px){
    img{
        // width: 90px;
    }
}
@media screen and (max-width: 800px){
    .swiper-slide{
        width: 300px;
    }
}
@media screen and (max-width: 600px){
    .home-friends-title{
        font-size: 30px;
    }
    .home-friends-items-item{
        width: 350px;
        // height: ;
    }
}

