@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=EB+Garamond:wght@500&family=Inter:wght@300&family=Open+Sans:ital,wght@1,300&family=PT+Sans+Narrow&family=Pacifico&family=Poppins:wght@200&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=EB+Garamond:wght@500&family=Inter:wght@300&family=Open+Sans:ital,wght@1,300&family=PT+Sans+Narrow:wght@400;700&family=Pacifico&family=Poppins:wght@200&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=EB+Garamond:wght@500&family=Inter:wght@300&family=Open+Sans:ital,wght@1,300&family=Pacifico&family=Poppins:wght@200&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=EB+Garamond:wght@500&family=Inter:wght@300&family=Open+Sans:ital,wght@1,300&family=PT+Sans+Narrow&family=Pacifico&family=Poppins:wght@200&family=Roboto&display=swap");



*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.container{
    max-width: var(--global-max-width);
    margin: auto;
}
body, html {
    /* overflow-x: hidden; */
    /* overflow-y: hidden; */
}
body{
    font-family: Arial, Helvetica, sans-serif;
}
p,span{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.055em;
}
:root{
    --global-homePage-color: #1A181D;
    --global-facultetPage-color: #0B0315;
    --global-max-width: 1300px;
    --blueviolet: #A35BFF;
    --white: #fff;
    --grey: #3E3E3E;
    --layout: #16131B;
    --light-red: #FF3535
}



@media screen and (max-width: 1200px){
    .container{
        width: 100%;
    }
}