

.home-faculties{
    padding: 0px 20px 0px 20px;

    width: 100%;
    margin: auto;
    &-title{
        text-align: center;
        // font-family: Arian AMU;
        font-size: 48px;
        font-weight: 700;
        color: var(--blueviolet);
    }
    &-items{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 60px;
        &-item{
            width: 405px;
            height: 600px;
            background: url(../../../assets/home/home-faculties/home-faculties-item.png) no-repeat center/auto;
            border: 3px solid var(--blueviolet);
            box-shadow: 1px 1px 15px black;
            border-radius: 20px;
            &:nth-of-type(2) &-top div{
                padding: 5px 0;
                background-color: #88BC51;
            }
            &:nth-of-type(3) &-top div{
                padding: 9px 0;
                background-color: #ED9014;
            }
            &-top{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-top: 35px;
                height: 96px;
                div{
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    background-color: #FF525D;
                    width: 180px;
                    border-radius: 0 15px 15px 0;
                    padding: 10px 0;
                    img{
                        width: 50px;
                    }
                }
                h3{
                    height: 100%;
                    // font-family: Arian AMU;
                    width: 200px;
                    font-size: 17px;
                    font-weight: 700;
                    letter-spacing: 0.055em;
                    color: var(--white);
                    margin-left: 15px
                }
            }
            &-bottom{
                padding: 50px 22px 45px 22px;
                text-align: center;
                &-item{
                    display: flex;
                    justify-content: space-between;
                    // gap: 65px;
                    align-items: center;
                    color: var(--white);
                    flex-wrap: wrap;
                    background-color: var(--grey);
                    margin-top: 20px;
                    padding: 0 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    position: relative;
                    h4{
                        // font-family: Arian AMU;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 45px;
                    }
                    button{
                        // font-family: Arian AMU;
                        font-size:10px;
                        font-weight: 700;
                        line-height: 26px;
                        color: #CAA0FF;
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        transition: all 200ms ease;
                        opacity: 1;
                    }
                    img{
                        opacity: 0;
                        position: absolute;
                        right: 0;
                        transition: all 300ms ease;
                    }
                    &:hover{
                        // justify-content: flex-end;
                        // gap: 54px;
                        // padding: 0;
                        button{
                            opacity: 0;
                        }
                        img{
                            opacity: 1;
                        }
                        
                    }
                }
                &-button{
                    text-align: center;
                    padding: 10px 55px;
                    // font-family: Arian AMU;
                    font-size: 24px;
                    font-weight: 700;
                    letter-spacing: 0.055em;
                    background-color: transparent;
                    border: 2px solid var(--blueviolet);
                    border-radius: 15px;
                    // margin-top: 50px;
                    color: var(--white);
                    box-shadow: 3px 3px 3px rgb(24, 24, 24);
                    cursor: pointer;
                    transition: all 300ms ease;
                    &:hover{
                        background: #894CD9;
                    }
                }
                
            }
            
        }

    }
}



.global-item-div{
    height: 464px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
// @keyframes appearItem {
//     0%{
//         opacity: 0;
//     }
//     100%{
//         opacity: 1;
//     }
//     0%{
//         opacity: 0;
//     }
// }


@media screen and (max-width: 1400px){
    .home-faculties-items{
        justify-content: center;
        // align-items: center;
        gap: 80px;
    }
}
@media screen and (max-width: 600px){
    .home-faculties-title{
        font-size: 36px;
    }
    
}
@media screen and (max-width: 450px){
    .home-faculties{
        margin-top: 40px;
    }
    .home-faculties-title{
        font-size: 30px;
    }
    .home-faculties-items-item{
            width: 330px;
    }
    .home-faculties-items-item-top{
        padding-top: 25px;
        h3{
            font-size: 15px
        }
    }
    .home-faculties-items-item-bottom{
        padding: 30px 18px 25px 18px;

    }
    .home-faculties-items-item-bottom-item{
        h4{
            font-size: 11px;
        }
        button{
            font-size: 9px;
        }
    }
    .home-faculties-items-item-bottom-button{
        padding: 9px 42px;
        font-size: 17px;
        margin-top: 25px;


    }
    
}