.blog-banner{
    // padding: 19vh 0 9vh 0;
    padding: 60px 0 0 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    &-description{
        position: relative;
        h1{
            width: 600px;
            font-family: Arial;
            font-size: 37px;
            font-weight: 700;
            line-height: 50px;
            color: var(--white);
        }
        p{
            width: 700px;
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.055em;
            color: var(--white);
            padding: 40px 0 35px 0;
        }
        &-img{
            // width: 100%;
            position: relative;
            img{
                width: 100%;
                height: 80vh;
                object-fit: cover;
            }
            .box{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background: #000;
                height: 80vh;
                opacity: 0.5;
            }
        }
        &-text{
            position: absolute;
            z-index: 10;
            top: 100px;
            padding-left: 50px;
            h3{
                font-size: 44px;
                color: var(--white);
                width: 550px;
            }
            p{
                font-size: 16px;
                font-family: Arial, Helvetica, sans-serif;
                line-height: 25px;
            }
        }
    }
}
.blog-banner .container{
        width: var(--global-max-width);
}