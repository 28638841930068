.expectations{
    padding: 100px 0;
    svg{
        display: block;
    }
    path{
        height: 40px;
    }
    .up{
        transform: rotate(-180deg);

    }
    h1{
        font-size: 46px;
        margin-bottom: 48px;
    }
    &-items{
        display: flex;
        flex-wrap: wrap;
        gap: 80px;
        
    }
    &-item{
        width: 190px;
        color: #f9f9f9;
        font-family: arial AMU;
        display: flex;
        flex-direction: column;
        align-items: center;
        >h6{
            font-size: 18px;
            font-weight: 400;
            margin: 20px 0 10px;
        }
        >p{
            font-size: 12px;
            font-weight: 100;
        }
    }
}



@media screen and (max-width: 1100px) {
    .expectations{
        &-items{
            justify-content: center;
        }
        h1{
            text-align: center;
        }
    }
}