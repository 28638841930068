.home-team{
    padding: 130px 80px 0 80px;
    &-title{
        text-align: center;
        // font-family: Arian AMU;
        font-size: 48px;
        font-weight: 700;
        color: var(--blueviolet)
    }
    &-items{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-item{
            margin-top: 60px;
            width: 300px;
            height: 440px;
            background: url(../../../assets/home/home-team/home-team-item-img.png) no-repeat center;
            // border-radius: ;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-text{
                width: 120px;
                padding: 20px 0 0 20px;
                h4{
                    color: var(--white);
                    // font-family: Arian AMU;
                    font-weight: 700;
                    line-height: 27px;
                    letter-spacing: 0.055em;
                }
            }
            &-icons{
                width: 100%;
                // padding: 15px 25px;
                text-align: center;
                display: flex;
                justify-content: space-between;
                background: #40404099;
                a{
                    padding: 20px 20px;
                    cursor: pointer;
                    width: 100px;
                }
                img{
                    width: 100%;
                    
                }
                img:not(:last-child){  
                    // border-right: 1px solid white;

                }
            }
        }
    }
}


@media screen and (max-width: 1100px){
    .home-team-items{
        justify-content: center;
        align-items: center;
        gap: 80px;
    }
}
@media screen and (max-width: 600px){
    .home-team-title{
        font-size: 36px;
    }
    .home-team-items-item{
        width: 300px;
        // height: ;
    }
    .home-team-items-item-icons{
        // gap: 5px;
    }
}
@media screen and (max-width: 450px){
    .home-team-title{
        font-size: 30px;
    }
}