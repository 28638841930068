.training-investment-background{
    margin-top: 70px;
    width: 100%;
    padding: 110px 20px;
    background-color: #A35BFF;

    .training-investment{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        &-text-side{
            width: 100%;
            >h1{
                 color: #fff;
                 margin-bottom: 30px;
            }
            h2{
                text-align: left;
                margin-bottom: 30px;

            }
        }
        &-button{
            >.button{
                padding: 14px 60px;
                border-radius: 5px;
                border: 1px solid #fff;
                box-shadow: 2px 2px 10px #000;
                background-color: #353535;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .training-investment{
        flex-direction: column;
        h1{
            text-align: center;
        }
        h2{
            font-size: 18px;
            font-weight: normal;
        }

    }
}
@media screen and (max-width: 430px) {
    .training-investment{
        flex-direction: column;

    }
}