.training-certificate{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-image{
        width: 100%;
        img{
            width: 100%;
            height: 100%;
        }
    }
    &-text-side{
        width: 100%;
        padding: 40px;
        color: #fff;
        h1{
            color: #fff;
            text-align: center;
            font-size: 34px;
            margin-bottom: 50px;
            font-weight: 600;
        }
        p{
            text-align: justify;
            margin-bottom: 20px;
            font-weight: 100;
        }
    }
}
@media screen and (max-width: 700px) {
    .training-certificate{
        flex-direction: column;
    }
}