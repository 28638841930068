.home-about{
    background: url(../../../assets//home//home-about/background.png) no-repeat center/cover;
    margin-top: 90px;
    padding: 0 80px ;
    width: 100%;
    .container{
        display: flex;
        justify-content: space-between;
    }
    &-title{
        width: 560px;
        padding: 70px 0 40px 0;
        color: var(--white);
        h2{
            font-size: 33px;
            list-style: 54px;
        }
        p{
            font-size: 18px;
            line-height: 32px;
            padding: 30px 0;
        }
        button{
            padding: 20px 110px 20px 110px;
            border-radius: 20px;
            background: transparent;
            color: var(--white);
            border: 1px solid #A35BFF;
            font-size: 18px;
        }
    }
    &-img{
        width: 530px;
        height: 500px;
        position: relative;
        &-item{
            background: linear-gradient(180deg, rgba(129, 119, 119, 0.489) -30.29%, rgba(255, 255, 255, 0) 144.92%);

            position: absolute;
            width: 240px;
            height: 170px;
            border: 1px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // filter: blur(5px);
            border: 2px solid rgba(130, 130, 130, 0.671);
            border-radius: 10px;
            color: var(--white);
            img{
                width: 50px;
                z-index: 10;
                position: relative;
                
            }
        }
    }
}
.home-about-img-item:first-child{
    top: 40px;
    left: 55px;
}
.home-about-img-item:nth-of-type(2){
    top: 140px;
    left: 310px;
}
.home-about-img-item:nth-of-type(3){
    bottom: 95px;
    left: 90px;

}
.home-about-img-item:nth-of-type(4){
    bottom: 0;
    right: -70px;
}