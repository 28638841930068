.AuthWindowItem{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 345px;
    height: 66px;
    border-radius: 33px;
    border: 1px solid #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: 0.3s ease;
    > p{
        color: #fff;
    }
}