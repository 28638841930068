.facultet{
    background-color: var(--global-homePage-color);
    padding: 100px 30px;
    .container{
        max-width: 1250px;
    }
    &-title{
        display: flex;
        align-items: center;
        gap: 50px;
        &-desc{
            p{
                font-family: Arial;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0.055em;
                color: var(--white);
                p{
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
        button{
            padding: 19px 58px;
            background: transparent;
            border-radius: 60px;
            color: var(--white);
            border: 1px solid var(--white);
            font-family: Arial;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.055em;
            cursor: pointer;
            transition: all 200ms ease;
            &:hover{
                background: var(--blueviolet);
                border: 1px solid var(--blueviolet);
            }
        }
    }
    &-professions{
        margin-top: 100px;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        &-left{
            width: 180px;
            &-item{
                text-align: center;
                padding: 55px 10px;
                background-color: #4b4848;
                border-radius: 0 0 25px 25px;
                transition: all 400ms ease;
                cursor: pointer;
                &:nth-of-type(1){
                    position: relative;
                    z-index: 3;
                }
                &:nth-of-type(2){
                    transform: translateY(-22px);
                    position: relative;
                    z-index: 2;
                }
                &:nth-of-type(3){
                    transform: translateY(-44px);  
                }
                img{
                    width: 40px;
                }
                h4{
                    font-size: 15px;
                    line-height: 25px;
                    padding-top: 10px;
                    color: var(--white);
                }
            }
        }
        &-items{
            display: flex;
            width: 1010px;
            gap: 30px;
            flex-wrap: wrap;
            &-item{
                width:310px;
                height: 524px;
                background: #151515;
                border-radius: 10px;
                transition: all 400ms ease;
                cursor: pointer;
                text-align: center;
                &-backimg{
                    width: 100%;
                    &-hover{
                        display: none;
                        width: 100%;
                    }
                }
                &-title{
                    text-align: center;
                    position: relative;
                    top: -70px;
                    // img{
                    //     border: 7px solid grey;
                    //     border-radius: 50%;
                    // }
                    &-img{
                        &-hover{
                            display: none;
                        }
                    }
                    p{
                        width: 150px;
                        margin: auto;
                        font-family: Arial;
                        font-weight: 700;
                        line-height: 18px;
                        letter-spacing: 0;
                        color: var(--white);
                    }
                }
                &-desc{
                    padding: 0 22px;
                    position: relative;
                    top: -50px;
                    text-align: left;
                    p{
                        font-family: Arial;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 25px;
                        letter-spacing: 0.03em;
                        color: var(--white);
                    }
                    >p:first-child{
                        color: #FF7C52;
                    }
                    span{
                        color: var(--white);
                        font-family: Arial;
                        font-size: 13px;
                    }
                }
                &-about{
                    padding: 0 22px;
                    width: 274px;
                    gap: 10px;
                    color: white;
                    display: flex;
                    flex-wrap: wrap;
                    font-family: Arial, Helvetica, sans-serif;
                    position: relative;
                    top: -30px;
                    span{
                        font-size: 14px;
                        img{
                            width: 13px;
                        }
                    }

                }
                &:hover{
                    transform: translate(-10px, -10px);
                    background: var(--white);
                    .facultet-professions-items-item-backimg{
                        display: none;
                        &-hover{
                            display: block;
                        }
                    }
                    .facultet-professions-items-item-title{
                        p{
                            color: #3A3A3A;
                        }
                    }
                    .facultet-professions-items-item-desc{
                        p{
                            color: #3A3A3A;
                        }
                        >p:first-child{
                            color: #FF7C52;
                        }
                        span{
                            color: #3A3A3A;
                        }
                    }
                    .facultet-professions-items-item-about{
                        color: #3A3A3A;
                    }
                    
                    .facultet-professions-items-item-title img:nth-of-type(1){
                        display: none;
                    }
                    .facultet-professions-items-item-title-img-hover{
                        width: 114px;
                        display: block;
                        margin: auto;
                    }
                }
                &-button{
                    text-align: center;
                    border-radius: 50px;
                    padding: 13px 53px;
                    background: transparent;
                    color: white;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.18px;
                    letter-spacing: 0.055em;
                    margin: 10px 0;
                    border: none;
                    border: 2px solid #B8B8B8
                }
                &:hover{
                    .facultet-professions-items-item-button{
                        text-align: center;
                    border-radius: 50px;
                    padding: 13px 53px;
                    background: var(--blueviolet);
                    color: white;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.18px;
                    letter-spacing: 0.055em;
                    margin: 10px 0;
                    border: none;
                    }
                }
            }  
        }
    }
}



@media screen and (max-width: 1250px){
    .facultet{

        &-professions{
            // flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            &-items{
                justify-content: center;
            }
            &-left{
                transform: rotate(-90deg);
                margin-top: -200px;
                &-item{
                    padding: 67px 10px;
                    div{
                        transform: rotate(90deg);
                    }

                }
            }
            &-items{
                margin-top: -200px;
            }
        }
    }
    
}

@media screen and (max-width: 1100px){
    .facultet{


        &-title{
            flex-direction: column;
            text-align: center;
        }
    }
    
}


@media screen and (max-width: 760px){
    .facultet{

        &-professions{
            &-left{
                transform: rotate(0);
                margin-top: 0;
                &-item{
                    padding: 67px 10px;
                    div{
                        transform: rotate(0);
                    }

                }
            }
            &-items{
                margin-top: 0;
            }
        }
    }
}   
@media screen and (max-width: 530px){
    .facultet-title button{
        padding: 13px 42px;
        font-size: 11px;
    }
    .facultet-title-desc p p{
        font-size: 15px;
    }
}



// @keyframes appearItem {
//     0%{
//         opacity: 0;
//     }
//     100%{
//         opacity: 1;
//     }
//     0%{
//         opacity: 0;
//     }
// }