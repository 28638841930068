.treaning{
    background-color: var(--global-homePage-color);
    padding: 0 10px;
}
@media screen and (max-width: 600px) {
    .treaning{
        padding: 0 10px;
    }
}
@media screen and (max-width: 430px) {
    .treaning{
        padding: 0 10px;
    }
}