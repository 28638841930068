.home-works{
    padding: 90px 20px 0px 20px;

    &-title{
        text-align: center;
        // font-family: Arian AMU;
        font-size: 48px;
        font-weight: 700;
        color: var(--blueviolet)
    }
    &-items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 90px;
        &-item{
            width: 405px;
            height: 600px;
            display: flex;
            // border: 2px solid white;
            border-radius: 20px;
            justify-content: space-between;
            flex-direction: column;
            padding: 30px 20px;
            &-deck{
                text-align: end;
                button{
                    padding: 0 12px;
                    border-radius: 20px;
                    //styleName: Text;
                    // font-family: Epilogue;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 40px;
                    border: none;
                }
            }
            &-title{
                width: 220px;
                h3{
                    // font-family: Epilogue;
                    font-size: 40px;
                    font-weight: 800;
                    // line-height: 48px;                
                    color: var(--white);        
                }
                p{
                    // font-family: Arian AMU;
                    font-size: 15px;
                    font-weight: 700;
                    color: var(--white);        
                    // line-height: 40px;
                }
            }
            
        }
        &-item:first-child{
            background: url(../../../assets/home/home-works/home-works-img1.png) no-repeat center/cover;
        }
        &-item:nth-of-type(2){
            background: url(../../../assets/home/home-works/home-works-img2.png) no-repeat center/cover;
        }
        &-item:last-child{
            background: url(../../../assets/home/home-works/home-works-img3.png) no-repeat center/cover;
        }
    }
}


@media screen and (max-width: 1400px){
    .home-works-items{
        justify-content: center;
        gap: 80px;
    }
}
@media screen and (max-width: 600px){
    .home-works-title{
        font-size: 36px;
    }
    .home-works-items-item{
        width: 350px;
        // height: ;
    }
}

@media screen and (max-width: 450px){
    .home-works-title{
        font-size: 30px;
    }
    .home-works-items-item{
        height: 500px;
    }
}