// h2,p{
//     color: #fff;
//     text-align: center;
// }

.training-works{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2,p{
        color: #fff;
        text-align: center;
    }
    &-title{
        width: 100%;
        h2{
            font-size: 30px;
            letter-spacing: 4px;
            margin-bottom: 30px;
        }
        p{
            margin: auto;
            width: 100%;
            
        }
    }
    &-projects{
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        &-item{
            background-color: #fff;
            border-radius: 20px;
            padding: 20px;
            transition: 0.5s ease;
            &-img{
                margin-top: 10px;
                width: 400px;
                height: 600px;
                border-radius: 20px;
                > img{
                    width: 100%;
                    height: 100%;
                }
            }
            >p{
                color: #757B82;
                text-align: left;
                transition: 0.5s ease;
            }
            >h4{
                transition: 0.5s ease;
            }
        }
        &-item:hover{
            background-color: #A35BFF;
            cursor: pointer;
            h4, p{
                color: #fff;
            }
        }
    }

}



@media screen and (max-width: 1420px) {
    .training-works{
        &-projects{
            flex-wrap: wrap;
        }
    }
    
}
@media screen and (max-width: 500px) {
    .training-works{
        &-projects{
            flex-wrap: wrap;
            &-item{
                width: 320px;
                height: 600px;
                &-img{
                    width: 280px;
                    height: 440px;
                }
            }
        }
    }
    
}