.training-form{
    background-color: rgba(54, 54, 54, 0.85);
    width: 700px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    color: #fff; 
    padding: 60px 60px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    backdrop-filter: blur(8px); 
    position: fixed;
    z-index: 100;
    >h2{
        text-align: center;
        color: #fff;
        font-weight: bold;
        margin-bottom: 60px;
    }
    p{
        text-align: center;
        font-weight: 700;
        margin-top: 30px;
    }
    input {
        border: none;
        background-color: #484848;
        border-radius: 10px; /* Optional: Adds rounded corners */
        outline: none;
        color: white;
        padding: 16px;
        width: 100%;
        margin-bottom: 40px;
    }
    input::placeholder{
        color: #ccc;
    }
    .btn{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    button{
        border: none;
        border-radius: 10px;
        color: #fff;
        background: linear-gradient(45deg, #A663DB, #6751B7);
        padding: 16px 80px;
        cursor: pointer;
    }
    
    .close-window{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .close-reg{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
        >p{
            position: absolute;
            top: 44%;
            left: 54%;
            transform: translate(-50%, -50%);
            margin: 0;
            line-height: 0;
        }
    }
}