footer{
    padding: 80px;
    background-color: var(--layout);
    .footer-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-about{
            width: 300px;
            img{
                width: 100%;
            }
            p{
                // font-family: Arian AMU;
                color: var(--white);
                font-size: 17px;
                font-weight: 400;
                line-height: 21px;
                padding: 20px 0 30px 0;
            }
            
        }
        &-firstList{
            padding-top: 35px;
            &-item{
                h4{
                    // font-family: Arian AMU;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0.055em;
                    color: var(--white);
                }
                p{
                    // font-family: Arian AMU;
                    cursor: pointer;
                    font-weight: 400;
                    line-height: 34px;
                    letter-spacing: 0.055em;
                    color: var(--white);
                    transition: 0.2s ease;
                    &:hover{
                        color: var(--blueviolet);

                    }
                }
            }
        }
        &-secondList{
            padding-top: 35px;

            &-item{
                p{
                    cursor: pointer;
                    // font-family: Arian AMU;
                    font-weight: 400;
                    line-height: 34px;
                    letter-spacing: 0.055em;
                    color: var(--white);
                    transition: 0.2s ease;
                    &:hover{
                        color: var(--blueviolet);
                    }
                }
            }
        }
        &-thirdList{
            padding-top: 35px;

            &-item{
                p{
                    // font-family: Arian AMU;
                    font-weight: 400;
                    line-height: 34px;
                    letter-spacing: 0.055em;
                    color: var(--white);
                    a{
                        outline: none;
                        text-decoration: none;
                        cursor: pointer;
                        color: var(--blueviolet);
                    }
                }
            }
        }
    }
    .footer-bottom{
        border-top: 2px solid var(--white);
        text-align: center;
        p{
            // width: 1000px;
            // margin: auto;
            // font-family: Arian AMU;
            font-size: 19px;
            font-weight: 400;
            line-height: 29px;
            color: var(--white);
            letter-spacing: 0.055em;
            
            padding-top: 40px;
        }
    }
}
iframe{
    width: 400px;
    height: 300px;
}
.responsive-text{
    display: none;
}

@media screen and (max-width: 1100px){
    footer{
        .footer-top{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // text-align: center;
            &-about{
                img{
                    width: 100%;
                }
                p{
                    display: none;
                }
            }
            &-firstList{
                display: none;
            }
            &-secondList{
                display: none;
            }
            &-thirdList{
            padding: 20px 0;
            }
        }
    }
    
}
@media screen and (max-width: 800px){
    footer{
        .responsive-text{
            display: block;
        }
        .footer-bottom p:first-child{
                display: none;
        }
    }       
}
@media screen and (max-width: 600px){
    
}

