.header {
  background-color: var(--layout);
  padding: 0 80px;
  position: fixed;
  width: 100%;
  z-index: 30;
  &-scrolled{
    
    transition: 0.7s ease;
    padding: 10px 65px;
    background-color: rgba(22, 19, 27, 0.5); /* Adjust for transparency */
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    will-change: backdrop-filter;
    li{

      // font-size:20px;
    }
    img{
      // width: 220px;
    }
  }
}

.row {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  img {
    width: 200px;
    cursor: pointer;
  }
}
.nav {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  li {
    // font-family: "Arian AMU";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.055em;
    color: rgba(255, 255, 255, 1);
    transition: all 200ms ease;
    cursor: pointer;
    &:hover {
      color: var(--blueviolet);
    }
  }
}
.options {
  display: flex;
  gap: 20px;
  align-items: center;
  svg {
    font-size: 23px;
    color: white;
    background: url(../../assets/header/Lang.png);
  }
  button {
    padding: 6px 13px;
    background-color: white;
    border-radius: 30px;
    border: none;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.055em;
    cursor: pointer;
  }
}
.burger {
  display: none;
}
.checkbox {
  display: none;
}
.hamburger-lines {
  display: none;
}

@media screen and (max-width: 1200px) {
  .logo {
    z-index: 10;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 0%;
    text-align: center;
    width: 100%;
    // padding-top: 80px;
    height: 100vh;
    justify-content: center;
    // background-color: black;
    z-index: 2;
    padding-left: 20px;
    overflow: auto;
    font-size: 40px;
    -webkit-scrollbar: none;
    padding: 80px 50px;
    display: none;
    text-align: left;
    background: #40404099;
    backdrop-filter: blur(24px);
    li {
      margin-top: 50px;
      font-size: 27px;
      padding-bottom: 7px;
      border-bottom: 1px solid #9f9f9f;
      // margin-left: 100px;
    }
    .logo {
      img {
        z-index: 10;
      }
    }
  }
  .options {
    display: none;
    position: absolute;
    z-index: 10;
    bottom: -561px;
    left: 50px;
    svg {
      font-size: 21px;
    }
    span {
      img {
        width: 23px;
      }
    }
    button {
      padding: 6px 10px;
    }
  }
  .burger {
    display: block;
  }
  .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 22px;
    right: 30px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 22px;
    right: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--white);
  }
  .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }
  .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .nav {
    display: none;
  }
  .options {
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 0 30px;
    img {
      width: 180px;
    }
  }
  .hamburger-lines .line {
    height: 4px;
  }
}
