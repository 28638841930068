.auth-window{
    padding: 98px 92px;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    height: 788px;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(18, 18, 18, 0.57);
    box-shadow: 0px 4px 95px 0px rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(33.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    >.close-button{
        position: absolute;
        top: 56px;
        right: 56px;
        cursor: pointer;
    }
    > .sign-in-up{
        margin: 50px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        > div{
            width: 150px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 50px 50px 0px;
            border: 0.5px solid rgba(255, 255, 255, 0.50);
            cursor: pointer;
            color: #fff;
            transition: 0.3s ease;

        }
        > div:first-child{
            border-radius: 50px 0px 0px 50px;
        }
    }
    > h4{
        color: #FFF;
        font-family: Arian AMU;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &-items{
        margin-top: 36px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    &-footer{
        margin-top: 78px;
        width: 100%;
        display: flex;
        justify-content: right;
        > .forward{
            padding: 20px 74px;
            border-radius: 100px;
            background: #864DE3;
            color: #fff;
            cursor: pointer;
        }
    }
    
}