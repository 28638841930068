.no-scroll {
    overflow-x: hidden;

}


.service-info-page {
    min-height: 100vh;
    background:
        url("../../../assets/service/background.png"),
        #252525 0px 0px / 100% 100% no-repeat;
    .service-info {
        // padding: 0 80px;
        ::-webkit-scrollbar {
            display: none;
        }
        &-description {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            padding: 140px 0 0 0;
            .text-side {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 760px;
                color: #fff;
                > h1 {
                    color: #98daff;
                    margin-bottom: 96px;
                    font-family: Arial;
                    font-size: 42px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 2.4px;
                }
                > p {
                    font-family: Arial;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 241.3%; /* 36.195px */
                    letter-spacing: 0.825px;
                }

                &-buttons {
                    > .button {
                        border-radius: 15px;
                        border: 2px solid #8f8f8f;
                        background-color: transparent;
                        color: #fff;
                        cursor: pointer;
                        transition: 1s;
                    }
                    > .button:hover {
                        background: linear-gradient(#a763db, #5a4eaf);
                    }
                }
            }
            .icon-side {
                position: relative;
                > img {
                    width: 100%;
                    height: 100%;
                }
                .round {
                    background-color: #1a181d;

                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    > img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 72px;
                        height: 72px;
                    }
                }
            }
        }
    }
}
.website-types {
    padding: 0 10px;
    h1 {
        color: var(--main-text-night, #fff);
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.2px;
        margin-bottom: 30px;
        margin-top: 110px;
    }
    &-items {
        display: flex;
        flex-wrap: wrap;
        gap: 22px;
    }
    &-item {
        padding: 10px 30px;
        border-radius: 10px;
        border: 0.5px solid #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        > p {
            color: #ececec;
            font-family: Arial;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 134.8%; /* 20.22px */
            letter-spacing: 0.825px;
        }
    }
}
.website-info {
    margin-top: 88px;
    padding: 0 10px;
    h1 {
        color: #fff;
        font-family: Arial;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 134.8%;
        letter-spacing: 1.98px;
        margin-bottom: 30px;
    }
    p {
        color: #fff;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 134.8%; /* 20.22px */
        letter-spacing: 2px;
        word-spacing: 6px;
    }
    .float {
        padding: 30px;
        padding-top: 0px;
        float: right;
    }
}
.service-form {
    max-width: 1300px;
    height:100vh;
    width: 90%;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background-color: #363636f9;
    color: #fff;
    padding: 20px 60px  ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    position: fixed;
    z-index: 100;
    font-size: 12px;
    font-weight: 200;

    h2 {
        font-family: Arial;
        font-size: 36px;
        font-weight: 700;
        line-height: 48.53px;
        letter-spacing: 0.055em;
        text-align: left;
        text-align: center;
        margin-bottom: 30px;
        span {
            font-family: Arial;
            font-size: 36px;
            font-weight: 700;
            line-height: 48.53px;
            letter-spacing: 0.055em;
            text-align: left;
        }
        .span-blue {
            color: #98daff;
        }
        .span-orange {
            color: #ff5942;
        }
        .span-green {
            color: #a1ff8f;
        }
    }
    .conatiner {
        max-width: 900px;
    }
    &-type {
        display: flex;
        p {
            width: 240px;
            font-family: Arial;
            font-size: 17px;
            font-weight: 400;
            line-height: 26.96px;
            letter-spacing: 0.055em;
        }
        &-select {
            padding: 15px 0px;
            padding-left: 6px;
            text-align: center;
            border: 1px solid #ffffff;
            border-radius: 10px;
            background-color: #363636;
            cursor: pointer;
            position: relative;
            .options-list {
                position: absolute;
                top: 100%; /* Появляется сразу под select */
                left: 0;
                width: 100%;
                background-color: #363636;
                border: 1px solid #ddd;
                border-radius: 10px;
                max-height: 200px;
                overflow-y: auto;
                transition:
                    opacity 0.3s ease-out,
                    visibility 0.3s ease-out;
                opacity: 0;
                visibility: hidden;
                z-index: 1;
            }

            .options-list.open {
                opacity: 1;
                visibility: visible;
            }

            .option {
                padding: 8px 12px;
                cursor: pointer;
                color: #fff;
                transition: background-color 0.2s ease-in-out;
            }

            .option:hover {
                background-color: var(--blueviolet);
            }
        }
    }
    &-budjet {
        display: flex;
        margin: 30px 0;
        p {
            width: 240px;
            font-size: 14px;
            font-weight: 200;
        }
    }
    .box {
        width: 180px;
        height: 50px;
        background-color: transparent;
        border: 1px solid #ffffff;
        color: var(--white);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-family: Arial;
            font-size: 13px;
            font-weight: 400;
            line-height: 20.22px;
            letter-spacing: 0.055em;
            text-align: left;
        }
        .option {
            cursor: pointer;
            font-family: Arial;
            font-size: 13px;
            font-weight: 400;
            line-height: 20.22px;
            letter-spacing: 0.055em;
            text-align: left;
        }
    }
    .send-form {
        display: flex;
        p {
            width: 240px;
        }
        form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            input {
                width: 310px;
                height: 50px;
                border-radius: 15px;
                background: #4b4b4b;
                color: #ffffff;
                border: none;
                outline: none;
                padding-left: 15px;
            }
            .textarea {
                width: 310px;
                height: 100px;
                border-radius: 15px;
                background: #4b4b4b;
                color: #ffffff;
                border: none;
                outline: none;
                padding: 15px 15px;
            }
            ::placeholder {
                color: #ccc;
                opacity: 1; /* Firefox */
            }

            ::-ms-input-placeholder {
                /* Edge 12-18 */
                color: #ccc;
            }
            button {
                background: linear-gradient(92.88deg, #a663db 9.74%, #6751b7 99.48%);
                width: 300px;
                height: 60px;
                border: none;
                border-radius: 15px;
                color: white;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }

    .close-window {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .close-reg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
        > p {
            position: absolute;
            top: 50%;
            left: 51%;
            transform: translate(-50%, -50%);
            margin: 0;
            line-height: 0;
        }
    }
}

.serivce-form-budjet-items {
    display: flex;
    gap: 30px;
    &-item {
        cursor: pointer;
        transition: 200ms ease;
    }
}

@media (max-width: 1250px) {
    .service-form{
        height: 80vh;
    }
    .service-form > .container {
        display: flex;
        justify-content: space-between;
    }
    .service-form-type {
        display: block;
    }
    .service-form-budjet {
        // margin: 0;
        margin: 0;
        display: block;
    }
    .serivce-form-budjet-items {
        flex-direction: column;
    }
    .send-form {
        flex-direction: column;
    }
    .service-form .box {
        width: 240px;
        height: 50px;
        font-size: 16px;
        margin-top: 10px;
    }
    form {
        input {
            min-width: 340px;
        }
    }
}

@media (max-width: 1094px) {
    .service-form {
        overflow-y: auto;
        height: 90vh;
    }
    .service-form .box {
        width: 90%;
        max-width: 375px;
        height: 50px;
        font-size: 14px;
    }
    .serivce-form-budjet {
        margin: 20px 0;
    }
    .serivce-form-budjet-items {
        flex-wrap: wrap;
        margin: auto;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
    }
    .send-form{
        margin-top: 15px;
    }
    form {
        margin: 15px 0;
        input {
            min-width: 100%;
        }
    }
    .service-form > .container {
        flex-direction: column;
        display: flex;
    }

    .training-form {
        max-width: 90%;
        padding: 15px 20px;
    }

    h1 {
        font-size: 22px;
    }

    label,
    .message {
        font-size: 14px;
    }

    .btn button {
        font-size: 16px;
    }
}


@media (max-width: 1094px) {
    .service-form .box{
        max-width: 350px;
    }
}
@media (max-width: 951px) {
    .service-form .box{
        max-width: 300px;
    }
}
@media (max-width: 842px){
    .service-form .box{
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .serivce-form-budjet-items {
        flex-wrap: wrap;
        margin: auto;
        // flex-direction: column;
        // justify-content: center;
        text-align: center;
    }
    .service-form .box {
        width: 300px;
        height: 50px;
        font-size: 14px;
        // margin-top: 10px;
    }

    .training-form {
        width: 100%;
        height: auto;
        padding: 15px;
        top: 10%;
        transform: none;
    }

    h1 {
        font-size: 20px;
    }
    input,
    .btn button {
        height: 45px;
        font-size: 16px;
    }

    .close-window {
        top: 10px;
        right: 10px;
    }
}

@media (max-width: 480px) {
    .service-form {
        padding: 20px 20px;
    }
    .send-form form input{
        max-width: 100%;
    }
    .service-form h2{
        font-size: 25px;
        line-height: 28px;
        span{
            font-size: 25px;
            line-height: 28px;
        }
    }
    .training-form {
        padding: 10px 15px;
    }

    h1 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    label {
        font-size: 14px;
    }

    input {
        height: 40px;
        font-size: 14px;
    }

    button {
        height: 45px;
        font-size: 16px;
        max-width: 100%;
    }
}






@media (max-width: 1250px) {
    .service-form > .container {
        display: flex;
        justify-content: space-between;
    }
    .service-form-type {
        display: block;
    }
    .service-form-budjet {
        // margin: 0;
        display: block;
    }
    .serivce-form-budjet-items {
        flex-direction: column;
    }
    .send-form {
        flex-direction: column;
    }
    .service-form .box {
        width: 240px;
        height: 50px;
        font-size: 16px;
        margin-top: 10px;
    }
    form {
        input {
            min-width: 340px;
        }
    }
}

@media (max-width: 1024px) {
    .service-form {
        overflow-y: auto;
    }
    .service-form .box {
        width: 300px;
        height: 50px;
        font-size: 14px;
        // margin-top: 10px;
    }
    .serivce-form-budjet {
        margin: 20px 0;
    }
    .serivce-form-budjet-items {
        flex-wrap: wrap;
        margin: auto;
        flex-direction: row;
        text-align: center;
        // justify-content: space-between;
    }
    form {
        input {
            min-width: 280px;
        }
    }
    .service-form > .container {
        flex-direction: column;
        display: flex;
    }

    .training-form {
        max-width: 90%;
        padding: 15px 20px;
    }

    h1 {
        font-size: 22px;
    }

    label,
    .message {
        font-size: 14px;
    }

    .btn button {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .serivce-form-budjet-items {
        flex-wrap: wrap;
        margin: auto;
        // flex-direction: column;
        // justify-content: center;
        text-align: center;
    }
    .service-form .box {
        width: 300px;
        height: 50px;
        font-size: 14px;
        // margin-top: 10px;
    }

    .training-form {
        width: 100%;
        height: auto;
        padding: 15px;
        top: 10%;
        transform: none;
    }

    h1 {
        font-size: 20px;
    }

    input,
    .btn button {
        height: 45px;
        font-size: 16px;
    }

    .close-window {
        top: 10px;
        right: 10px;
    }
}

@media (max-width: 480px) {
    .training-form {
        padding: 10px 15px;
    }

    h1 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    label {
        font-size: 14px;
    }

    input {
        height: 40px;
        font-size: 14px;
    }

    button {
        height: 45px;
        font-size: 16px;
        max-width: 100%;
    }
}

@media screen and (max-width: 1350px) {
    .service-info-page {
        // padding: 0 80px;
    }
    .service-info {
        &-description {
            padding-top: 126px;
        }
    }
    .icon-side {
        width: 412px;
        height: 412px;
    }
    .round {
        width: 280px;
        height: 280px;
    }
    .text-side {
        &-buttons {
            display: flex;
            justify-content: left;
            align-items: center;
            gap: 82px;
            margin-top: 60px;
        }
    }
    .float {
        float: right;
    }
}

@media screen and (max-width: 700px) {
    .float {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 501px) {
    .round {
        width: 280px;
        height: 280px;
    }
    .text-side {
        &-buttons {
            .button {
                padding: 18px 34px;
            }
        }
    }
}
@media screen and (max-width: 1350px) {
    .service-info-page {
        // padding: 0;
    }

    .service-info {
        &-description {
            flex-direction: column-reverse;
            flex-wrap: wrap;
            padding-top: 126px;
        }
    }
    .text-side {
        padding: 0 20px;
        > p {
            text-align: justify;
        }
        > h1 {
            text-align: center;
        }
        &-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 82px;
            margin-top: 60px;
        }
    }
}



@media screen and (max-width: 500px) {
    .icon-side {
        width: 300px;
        height: 300px;
    }
    .round {
        width: 200px;
        height: 200px;
    }
    .text-side {
        padding: 0 10px;
        &-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-top: 60px;
            > .button {
                padding: 10px 20px;
            }
        }
    }
    .website-types {
        &-items {
            flex-direction: column;
            padding: 0 10px;
        }
        h1 {
            text-align: center;
        }
    }
    .landing-site {
        h1 {
            text-align: center;
        }
    }
}
@media screen and (max-width: 400px) {
    .text-side {
        &-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-top: 60px;
        }
    }
    .service-info {
        padding: 0 4px;
    }
}
