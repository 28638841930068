.no-scroll{
    overflow: hidden;
}

.service-page{
    min-height: 100vh;
    // padding: 20px;
    background-color: var(--global-homePage-color);
    .service{
        h1{
            color: var(--main-text-night, #FFF);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 2.4px;
            padding: 100px 0;
            text-align: center;
        }
        &-items{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            > .service-item{
                width: 360px;
                height: 548px;   
                border-radius: 20px;
                background: #071112;
                display: flex;
                flex-direction: column;
                align-items: center;

                
                // padding-top: 300px;
                > .for-img{
                    position: relative;
                    width: 100%;
                    height: 230px;
                    margin-bottom: 80px;
                    >img{
                        width: 100%;
                    }
                    > .round{
                        background-color: #1A181D;
                        width: 160px;
                        height: 160px;
                        border-radius: 50%;
                        position: absolute;
                        top: 85%;
                        left: 50%;
                        transform:translate(-50%, -50%);
                        > img{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 72px;
                            height: 72px;
                        }
                    }
                }
                .text-side{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 52px;
                    padding: 0px 4px;
                    height: 70px;
                    h2{
                        color: var(--white);
                        text-align: center;
                        line-height: 40px;
                    }
                }
                
                > button {
                    background-color: #7834E7;
                    border-radius: 175px;
                    padding: 16px 56px;
                    border: none;
                    color: #fff;
                    cursor: pointer;
                    margin-top: 20px;
                    letter-spacing: 1.5px;
                }
            }
        }
    }
    
}

@media screen and (min-width: 1200px) {
    h1{
        color: black;
        font-size: 48px;
    }
}
@media screen and (max-width: 1200px) {
    .service-items{
        flex-wrap: wrap;
    }
    h1{
        font-size: 36px;
    }

}

@media screen and (max-width: 420px) {

    h1{
        font-size: 24px;
    }

}