.blog{
    padding: 15vh 80px;
    background: var(--global-homePage-color);
    color: white;

    &-title{
        text-align: center;
        h2{
            color: white;
            font-family: Arial;
            font-size: 48px;
            font-weight: 700;
            letter-spacing: 0.055em;
            // color: #190650;
        }
        &-icons{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            span{
                margin: 0 10px;
                display: flex;
                width: 40px;
                height: 40px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: #55ACE3;
                cursor: pointer;
                svg{
                    color: white;
                    font-size: 20px;
                }
                &:nth-of-type(2){
                    background: #4E6297;
                }
                &:nth-of-type(3){
                    background: linear-gradient(43.94deg, #EEA054 16.16%, #D64763 50.11%, #B62A99 83.68%);

                }
                &:nth-of-type(4){
                    background: #2878B7;
                }
            }
        }
    }
    &-items{
        max-width: 1000px;
        margin: 90px auto;
        &-item{
            display: flex;
            align-items: center;
            border-radius: 10px;
            box-shadow: 2px 2px 15px 0px var(--blueviolet);

            &:not(:first-child){
                margin-top: 60px;
            }
            img{
                width:500px;
            }
            &-btn{
                padding: 8px 20px;
                border: solid 2px #fff;
                background-color: transparent;
                color: #fff;
                border-radius: 10px;
                transition: all 300ms;
                cursor: pointer;
                &:hover{
                    background-color: #7834E7;
                    border: solid 2px #7834E7;
                    color: white;
                }
            }
            &-desc{
                padding-left: 30px;
                h3{
                    font-family: Arial;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 23px;
                    letter-spacing: 0.055em;
                }
                p{
                    font-family: Arial;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 25px 0 60px 0;
                }
            }
        }
    }
}





@media screen and (max-width: 1120px){
    .blog{
        &-items{
            &-item{
                h3{
                    font-size: 17px;
                }
                p{
                    font-size: 12px;
                    line-height: 16px;
                    
                }
                img{
                    width: 450px;
                }  
            }
        }
    }
}
@media screen and (max-width: 1020px){
    .blog{
        &-items{
            &-item{
                img{
                    width: 400px;
                }  
                &-desc{
                    padding-left: 12px;
                    h3{
                        font-size: 15px;
                    }
                    p{
                        font-size: 10px;
                        line-height: 17px;
                        padding: 15px 0 40px 0
                    }
                }
                
            }
        }
    }
}

@media screen and (max-width: 910px){
    .blog{
        &-items{
            &-item{
                img{
                    width: 340px;
                }  
                &-desc{
                    padding-left: 12px;
                    h3{
                        font-size: 13px;
                    }
                    p{
                        font-size: 10px;
                        line-height: 17px;
                        padding: 15px 0 30px 0
                    }
                }
                
            }
        }
    }
}

@media screen and (max-width: 825px){
    .blog{
        &-items{
            
            &-item{
                display: block;
                img{
                    width: 100%;
                }  
                &-desc{
                    padding: 25px;
                    h3{
                        font-size: 20px;
                        line-height: 23px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                
            }
        }
    }
}


@media screen and (max-width: 600px){
    .blog{
        &-items{
            &-item{
                &-desc{
                    padding: 20px;
                    h3{
                        font-size: 17px;
                        line-height: 20px;
                    }
                    p{
                        font-size: 12px;
                        line-height: 17px;
                    }
                }
                
            }
        }
    }
}
@media screen and (max-width: 500px){
    .blog{
        padding: 15vh 50px;
    }
}

@media screen and (max-width: 450px){
    .blog{
        &-items{
            &-item{
                &-desc{
                    padding: 15px;
                    h3{
                        font-size: 15px;
                        line-height: 18px;
                    }
                    p{
                        font-size: 11px;
                        line-height: 15px;
                    }
                }
                
            }
        }
    }
}