.blog-item-bottom{
    padding: 0 50px;

    .container{
        display: flex;
        gap: 30px;
    }
    &-left-news{
        margin: 30px 0 30px 0;
        // z-index: 10;
        width: 800px;
        padding: 35px;
        box-shadow: 0px 0px 15px 0px #0000001A;
        border-radius: 20px;
        // position: relative;
        &-title{
            font-family: Arial;
            font-size: 30px;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: 0.055em;
        }
        &-text{
            font-family: Arial;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.055em;
            padding-top: 30px;
        }
        &-topImg{
            width: 100%;
            padding-top: 30px;
        }
        &-bottomImg{
            padding: 30px 0;
            display: flex;
            justify-content: space-between;
            img{
                width: 350px;
            }
        }
    }
    &-similarTopic{
        margin-top: 40px;
        &-title{
            font-family: Arial;
            font-size: 30px;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: 0.055em;
            text-align: left;
            color: var(--grey);

        }
        &-items{
            margin-top: 40px;
            &-item{
                display: flex;
                gap: 20px;
                margin-top: 20px;
                border-bottom: 1px solid #00000080;
                padding: 0 0 8px 0;
                p{
                    width: 165px;
                }
            }
        }
    }
}


@media screen and (max-width: 1225px) {
    .blog-item-bottom-similarTopic{
        display: none;
    }
    .blog-item-bottom{
        .container{
            justify-content: center;
            width: 100%;
        }
    }
}


@media screen and (max-width: 885px) {
    .blog-item-bottom-left-news-bottomImg{
        flex-direction: column;
        width: 100%;
        gap: 20px;
        img{
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 775px){
    .blog-banner-description-text h3{
        font-size: 37px;
        width: 100%;
    }
    .blog-banner-description-text p{
        width: 100%;
        font-size: 15px;
        line-height: 23px;
    }
    .blog-banner-description-text{
        padding: 0 25px;
    }
}
@media screen and (max-width: 600px){
    .blog-item-bottom-left-news-title{
        font-size: 25px;
    }
    .blog-item-bottom-left-news-text{
        font-size: 14px;
        line-height: 23px;
    }
}
@media screen and (max-width: 520px){
    .blog-item-bottom-left-news-title{
        font-size: 21px;
        line-height: 27px;
    }
    .blog-item-bottom-left-news-text{
        font-size: 13px;
        line-height: 20px;
    }
    .blog-item-bottom{
        padding: 0 20px;
    }
}

@media screen and (max-width: 475px){
    .blog-banner-description-text h3 {
        font-size: 30px;
    }
    .blog-banner-description-text p {
        line-height: 22px;
        font-size: 14px;
    }
    .blog-banner-description-img .box{
        height: 68vh;
    }
    .blog-banner-description-img img{
        height: 68vh;
    }



    .blog-item-bottom-left-news-title{
        font-size: 19px;
        line-height: 27px;
    }
    .blog-item-bottom-left-news-text{
        line-height: 17px;
        font-size: 12px;
    }
    
}

