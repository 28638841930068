.contact {
    position: fixed;
    top: 53%;
    left: 50%;
    z-index: 50;
    transform: translate(-50%, -50%);
    color: var(--white);
    width: 600px;
    height: 90vh;
    padding: 36px;
    border-radius: 10px;
    overflow: hidden; /* No scrolling */
    background-color: rgba(22, 19, 27, 0.5); /* Adjust for transparency */
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    will-change: backdrop-filter;
    &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
        color: #fff;
        > h1 {
            font-size: 34px;
            letter-spacing: 2px;
            color: #fff;
        }
    }
    &-social {
        width: 500px;
        margin: auto;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        &-item {
            background: #59267CCC;
            text-decoration: none;
            color: #fff;
            border-radius: 13px;
            width: 198px;
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            span {
                display: flex;
            }
        }
    }
    &-form {
        color: var(--white);
        h3 {
            text-align: center;
            padding: 20px 0;
        }
        form {
            margin: auto;
            // width: 420px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            input {
                padding: 12px 8px;
                background-color: #7A7A7A40;
                border: none;
                outline: none;
                color: #fff;
                border-radius: 8px;
            }
            ::-webkit-input-placeholder {
                color: #fff;
            }
            > button {
                background-color: #6C33A3;
                width: 196px;
                margin: auto;
                border: none;
                color: #fff;
                padding: 12px 24px;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
    .close-contact {
        width: 24px;
        height: 24px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 20px;
        right: 20px;
        border: 1px solid #ccc;
        border-radius: 50%;
        font-size: 14px;
        cursor: pointer;
    }
}

/* Стили для календаря */

.date {
    position: relative;
    width: 100%;
}

.custom-date-picker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
    color: #fff;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #fff;
    font-weight: bold;
    font-size: 0.944rem;
}
.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}
.date-picker-icon {
    position: absolute;
    top: 12px;
    right: 12px;
}

.react-datepicker__header {
    background-color: #6d25d3cc;
    color: white;
}
.react-datepicker__day-name {
    color: #fff;
}

.react-datepicker__day--selected {
    background-color: #0088CC;
    color: white;
}

.react-datepicker__day:hover {
    background-color: #ccc;
}

@media screen and (max-width: 700px) {
    .contact {
        width: 90%;
        padding: 20px;
        &-social {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &-form {
            form {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                input {
                    width: 100%;
                }
            }
        }
    }
}